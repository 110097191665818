import { Link } from 'react-router-dom';

const products = [
  {
    "category": "Fire Safety Product",
    "item": "Fire Protection System",
    "brands": ["NAFFCO", "SFFECO"],
    "description": "A fire hydrant system is a crucial part of a building's fire protection infrastructure. It ensures the safety of occupants, protects property, and helps comply with fire safety regulations.",
    "image": "assets/Products/Fire Protection System.jpg",
    "standards": [
      "NFPA 1: Fire Code",
      "NFPA 13: Standard for the Installation of Sprinkler Systems",
      "NFPA 14: Standard for the Installation of Standpipe and Hose Systems",
      "NFPA 24: Standard for the Installation of Private Fire Service Mains and Their Appurtenances",
      "NFPA 25: Standard for the Inspection, Testing, and Maintenance of Water-Based Fire Protection Systems",
      "NFPA 291: Recommended Practice for Fire Flow Testing and Marking of Hydrants",
      "NFPA 101: Life Safety Code",
      "NFPA 5000: Building Construction and Safety Code"
    ]
  },
  {
    "category": "Fire Safety Product",
    "item": "Fire Detection System",
    "brands": ["Hochiki", "Cooper", "Tanda"],
    "description": "A fire detection system is essential for the safety of occupants, protection of property, and overall fire risk management in buildings.",
    "image": "assets/Products/Fire Detection System.jpg",
    "standards": [
      "NFPA 72: National Fire Alarm and Signaling Code",
      "NFPA 720: Standard for the Installation of Carbon Monoxide Detection and Warning Equipment"
    ]
  },
  {
    "category": "Fire Safety Product",
    "item": "Fire Door",
    "brands": ["NAFFCO", "ASICO"],
    "description": "A fire door is a critical component of a building's passive fire protection system. It plays a significant role in enhancing safety by controlling the spread of fire, smoke, and heat.",
    "image": "assets/Products/Fire Door.jpg",
    "standards": [
      "NFPA 80: Standard for Fire Doors and Other Opening Protectives",
      "NFPA 101: Life Safety Code",
      "NFPA 105: Standard for Smoke Door Assemblies and Other Opening Protectives",
      "NFPA 252: Standard Methods of Fire Tests of Door Assemblies"
    ]
  },
  {
    "category": "Power",
    "item": "Rooftop Solar",
    "brands": ["JINO", "JA", "LONGI", "AE Solar"],
    "description": "A rooftop solar power plant is a beneficial investment for industries due to several financial, operational, and environmental advantages.",
    "image": "assets/Products/Rooftop Solar.jpg",
    "standards": [
      "IS 875 (Part 1–5): Code of Practice for Design Loads (other than Earthquake) for Buildings and Structures in India",
      "IS 800: General construction in steel – Code of Practice",
      "IS 456: Code of Practice for Plain and Reinforced Concrete",
      "IEC 60364: Low-voltage electrical installations",
      "IS 3043: Code of Practice for Earthing",
      "IEEE 1547: Interconnection Standards for Distributed Energy Resources",
      "IEC 60947: Standards for switchgear and control gear components",
      "IEC 61215: Testing for design qualification and approval for crystalline silicon solar modules",
      "IEC 61730: Safety qualification for photovoltaic modules",
      "UL 1703: Standards for PV module safety in North America",
      "IEC 62109-1 & IEC 62109-2: Safety of power converters for use in photovoltaic systems",
      "IEC 61683: Efficiency testing for solar inverters",
      "UL 1741: Standards for inverters, converters, and controllers for North America",
      "IS 694: PVC Insulated Cables for Working Voltages up to and including 1100 Volts",
      "IEC 60227 / IEC 60502: Standards for low-voltage cables",
      "IS 1554 (Part 1 & 2): PVC insulated cables for working voltage up to and including 1100V and 3300V",
      "NEC (National Electrical Code): Guidelines for proper wiring and cabling",
      "IS 3043: Code of Practice for Earthing",
      "IEC 62305: Protection against lightning",
      "NFPA 780: Installation of lightning protection systems",
      "IS 15707: Testing, inspection, and maintenance of PV systems",
      "OSHA Standards: Occupational safety and health standards for installation and maintenance",
      "IEC 61701: Salt mist corrosion testing for solar modules (for coastal areas)",
      "IEC 62716: Ammonia corrosion testing for PV modules (for agricultural zones)",
      "IEC 61853: PV module performance testing",
      "IEC 61724: Monitoring and evaluating the performance of PV systems",
      "IEC 61277: General requirements for concentrating PV modules",
      "CEA Guidelines (India): Technical standards for connectivity of distributed generation resources",
      "IEEE 2030: Guidelines for smart grid interconnection",
      "EN 50438: Requirements for micro-generation grid connection (European Union)",
      "ISO 14001: Environmental management systems",
      "IS 2062: Specification for steel (for module mounting structures)",
      "IEC 62716: Ammonia corrosion testing for PV panels in specific environmental conditions"
    ]
  },
  {
    "category": "Structural",
    "item": "Steel Building Structure",
    "brands": [],
    "description": "Steel-fabricated buildings offer numerous benefits, making them a popular choice for various industrial, commercial, and even residential applications.",
    "image": "assets/Products/Steel Building Structure.jpg",
    "standards": [
      "IS 800: General Construction in Steel – Code of Practice (India)",
      "AISC 360: Specification for Structural Steel Buildings (American Institute of Steel Construction)",
      "BS 5950: Structural Use of Steelwork in Building (British Standard)",
      "Eurocode 3 (EN 1993): Design of Steel Structures (European Standard)",
      "IS 2062: Specification for Hot Rolled Medium and High Tensile Structural Steel",
      "ASTM A36/A36M: Standard Specification for Carbon Structural Steel (USA)",
      "EN 10025: Hot-rolled structural steel grades and qualities (Europe)",
      "JIS G3101: Rolled Steels for General Structure (Japan)",
      "IS 816: Code of Practice for Use of Metal Arc Welding for General Construction",
      "AWS D1.1: Structural Welding Code – Steel (American Welding Society)",
      "ISO 3834: Quality Requirements for Fusion Welding of Metallic Materials",
      "BS EN 1011: Welding recommendations for metallic materials",
      "IS 7215: Tolerances for Fabrication of Steel Structures",
      "IS 4000: Code of Practice for High-Strength Bolted Steel Joints",
      "AISC Code of Standard Practice: Guidelines for fabrication and erection (USA)",
      "BS EN 1090: Execution of Steel Structures and Aluminum Structures",
      "IS 14246: Specifications for Galvanized Steel Sheets (Plain and Corrugated)",
      "ASTM A653/A653M: Standard Specification for Steel Sheet, Zinc-Coated by the Hot-Dip Process",
      "EN 10169: Steel and Aluminum products with organic coatings",
      "IS 875 (Part 1–5): Code of Practice for Design Loads (Other than Earthquake) for Buildings and Structures",
      "IS 1893 (Part 1): Criteria for Earthquake Resistant Design of Structures",
      "ASCE 7: Minimum Design Loads for Buildings and Other Structures (USA)",
      "Eurocode 8 (EN 1998): Design of Structures for Earthquake Resistance",
      "IS 4759: Hot-dip Zinc Coatings on Structural Steel",
      "ASTM A123/A123M: Zinc (Hot-Dip Galvanized) Coatings on Iron and Steel Products",
      "ISO 12944: Paints and Varnishes – Corrosion Protection of Steel Structures by Protective Paint Systems",
      "IS 1641: Code of Practice for Fire Safety in Buildings (General Principles of Fire Grading and Classification)",
      "IS 1642: Code of Practice for Fire Safety of Buildings (Details of Construction)",
      "NFPA 5000: Building Construction and Safety Code (USA)",
      "BS 476: Fire Tests on Building Materials and Structures (UK)",
      "IS 1608: Tensile Testing of Metallic Materials",
      "IS 2062: Chemical and Physical Testing of Structural Steel",
      "ISO 17640: Ultrasonic Testing of Welds",
      "ASTM E164: Radiographic Examination of Welds",
      "ISO 14001: Environmental Management Systems",
      "LEED Standards: Green building certification",
      "IS 15914: Guidelines for Pre-Engineered Steel Buildings"
    ]
  },
  {
    "category": "Electrical",
    "item": "Lift",
    "brands": ["Sigma", "Fuji", "Soldier"],
    "description": "Selecting a good lift (elevator) for a building involves several factors to ensure safety, efficiency, and suitability for the building’s specific needs.",
    "image": "assets/Products/Lift.jpg",
    "standards": [
      "EN 81-20 / EN 81-50: European Standard for the safety of lifts",
      "ISO 25745: Energy performance of lifts, escalators, and moving walks",
      "IEC 60204-1: Safety of machinery — Electrical equipment of machines — Part 1: General rules",
      "ASME A17.1: Safety Code for Elevators and Escalators (USA)",
      "BS 5655: British Standard for lifts, covering installation, operation, and maintenance requirements",
      "IS 14665: Indian standard for elevators (elevators for general purposes)",
      "IEC 60947: Low-voltage switchgear and control gear",
      "IEC 61000-6: Electromagnetic compatibility (EMC)",
      "NEC (National Electrical Code): Standards for electrical installations, including requirements for lift wiring and electrical components",
      "IS 14665 (India): Guidelines for the design and installation of lifts and escalators, ensuring structural integrity and mechanical safety",
      "EN 81-1: Standards for design and construction of lifts, including structural design and materials used for load-bearing components like cables and rails",
      "ISO 3812: Lifts — Ropes and cables used in lifts",
      "ADA (Americans with Disabilities Act): Specifies requirements for accessibility in lifts",
      "EN 81-70: Safety rules for the construction and installation of lifts — Accessibility for disabled persons",
      "EN 81-72: Firefighters' lifts",
      "BS 9999: Code of practice for fire safety in the design, management, and use of buildings",
      "NFPA 101: Life Safety Code (USA)",
      "IS 1641: Fire safety code for buildings, including lift shaft fire prevention and emergency evacuation considerations in India",
      "EN 13015: Maintenance for lifts and escalators",
      "ISO 9001: Quality management standards",
      "ASME A17.2: Guide for Maintenance, Inspection, Testing, and Repair of Elevators and Escalators",
      "ISO 14001: Environmental management systems",
      "ISO 50001: Energy management systems",
      "ISO 10137: Lifts — Design of lifts to minimize vibrations and noise during operation",
      "EN 12015: Electromagnetic compatibility — Emissions and immunity levels for lifts to reduce noise interference with other electrical systems",
      "EN 81-58: Fire-rated lifts and escape lifts",
      "NFPA 72: National Fire Alarm and Signaling Code",
      "IEC 61508: Functional Safety of Electrical/Electronic/Programmable Electronic Safety-related Systems"
    ]
  },
  {
    "category": "Fire Safety Product",
    "item": "Fire Gypsum",
    "brands": ["Gyproc"],
    "description": "Fire-rated gypsum is essential in construction due to its ability to enhance the fire resistance of walls, ceilings, and other structural elements.",
    "image": "assets/Products/Fire Gypsum.jpg",
    "standards": [
      "EN 520: Gypsum plasterboards – Definitions, requirements, and test methods",
      "EN 13501-2: Fire classification of construction products and building elements – Part 2: Classification using data from fire resistance tests",
      "ISO 1182: Reaction to fire tests for building materials – Non-combustibility test",
      "ASTM E119: Standard Test Methods for Fire Tests of Building Construction and Materials",
      "ASTM C1396: Standard Specification for Gypsum Board",
      "NFPA 251: Standard Methods of Fire Tests of Building Construction and Materials"
    ]
  },
  {
    "category": "Fire Safety Product",
    "item": "Fire Paint",
    "brands": ["NAFFCO"],
    "description": "Fire-rated paint is a protective coating applied to various surfaces, primarily structural materials like steel, wood, and concrete, to improve their fire resistance.",
    "image": "assets/Products/Fire Paint.jpg",
    "standards": [
      "ASTM E119: Standard Test Methods for Fire Tests of Building Construction and Materials",
      "ASTM D3359: Standard Test Methods for Measuring Adhesion by Tape Test",
      "ASTM E84: Standard Test Method for Surface Burning Characteristics of Building Materials",
      "UL 263: Standard for Fire Tests of Building Construction and Materials",
      "EN 13501-2: Fire classification of construction products and building elements – Part 2: Classification using data from fire resistance tests",
      "EN 13381-8: Test methods for the fire resistance of structural elements – Part 8: Applied fire protection to steel elements",
      "BS 476-20: Fire tests on building materials and structures — Part 20: Method for determination of the fire resistance of loadbearing elements"
    ]
  },
  {
    "category": "Fire Safety Product",
    "item": "Fire Curtain",
    "brands": ["NAFFCO"],
    "description": "A fire curtain is a critical component in fire safety systems, designed to prevent the spread of fire and smoke within a building.",
    "image": "assets/Products/Fire Curtain.jpg",
    "standards": [
      "NFPA 80: Standard for Fire Doors and Other Opening Protectives",
      "NFPA 101: Life Safety Code",
      "NFPA 252: Fire Tests of Door Assemblies"
    ]
  },
  {
    "category": "Structural",
    "item": "Building Retrofitting",
    "brands": ["UE"],
    "description": "Building retrofitting is needed to enhance the safety, efficiency, and functionality of existing structures.",
    "image": "assets/Products/Building Retrofitting.jpg",
    "standards": [
      "Bangladesh National Building Code (BNBC)",
      "BNBC Section on Seismic Design"
    ]
  },
  {
    "category": "Power",
    "item": "Sub-Station",
    "brands": ["UE"],
    "description": "A power substation is essential for the efficient and reliable distribution of electrical power from the generation source to consumers.",
    "image": "assets/Products/Sub-Station.jpg",
    "standards": [
      "Bangladesh National Building Code (BNBC)"
    ]
  },
  {
    "category": "Electrical",
    "item": "Lightning Protection System (LPS)",
    "brands": ["UE", "ABB"],
    "description": "A Thunder Protection System, often referred to as a Lightning Protection System (LPS), is designed to protect buildings, structures, and people from the damaging effects of lightning strikes.",
    "image": "assets/Products/Lightning Protection System (LPS).jpg",
    "standards": [
      "IEC 62305: International standard for lightning protection",
      "NFPA 780: Standard for the Installation of Lightning Protection Systems (USA)",
      "BS 6651: British Standard for lightning protection (replaced by BS EN 62305)",
      "UL 96A: Standard for the Installation of Lightning Protection Systems (USA)"
    ]
  },
  {
    "category": "Power",
    "item": "Generator",
    "brands": ["Perkins", "Cummins", "Richardo"],
    "description": "Range 50 KW to 5 MW",
    "image": "assets/Products/Generator.jpg",
    "standards": []
  }
];


const Products = () => {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <div className="text-center mb-12">
        <h1 className="text-4xl font-bold text-gray-900 mb-4">Our Products</h1>
        <p className="text-xl text-gray-600">
          Comprehensive range of engineering and safety solutions
        </p>
      </div>

      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
        {products.map((product, index) => (
          <div key={index} className="bg-white rounded-lg shadow-lg overflow-hidden hover:shadow-xl transition-shadow">
            <Link to={`/product/${index}`}>
              <div className="overflow-hidden">
                <img
                  src={product.image}
                  alt={product.item}
                  className="w-full h-48 object-cover"
                />
                <div className="p-6">
                  <h3 className="text-xl font-bold mb-2">{product.item}</h3>
                  <p className="text-gray-600 mb-4">{product.description}</p>
                  <p className="text-gray-500">Category: {product.category}</p>
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Products;