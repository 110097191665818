const tools = [
  {
    name: "System Design Calculator",
    description: "Calculate power requirements and system specifications",
    icon: "🔧"
  },
  {
    name: "Product Configurator",
    description: "Configure and customize product specifications",
    icon: "⚙️"
  },
  {
    name: "Energy Savings Calculator",
    description: "Estimate potential energy savings and ROI",
    icon: "💡"
  }
];

const Tools = () => {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <div className="text-center mb-12">
        <h1 className="text-4xl font-bold text-gray-900 mb-4">Engineering Tools</h1>
        <p className="text-xl text-gray-600">
          Professional tools to help with your engineering calculations and designs
        </p>
      </div>

      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
        {tools.map((tool, index) => (
          <div key={index} className="bg-white p-6 rounded-lg shadow-lg">
            <div className="text-4xl mb-4">{tool.icon}</div>
            <h2 className="text-xl font-bold mb-2">{tool.name}</h2>
            <p className="text-gray-600 mb-4">{tool.description}</p>
            <button className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition-colors">
              Launch Tool
            </button>
          </div>
        ))}
      </div>

      <div className="mt-12 bg-gray-50 p-8 rounded-lg">
        <h2 className="text-2xl font-bold mb-4">Industry Standards & Codes</h2>
        <div className="grid md:grid-cols-2 gap-4">
          <div>
            <h3 className="font-semibold mb-2">Electrical Standards</h3>
            <ul className="list-disc list-inside text-gray-600">
              <li>IEEE Standards</li>
              <li>IEC Standards</li>
              <li>National Electrical Code</li>
            </ul>
          </div>
          <div>
            <h3 className="font-semibold mb-2">Safety Guidelines</h3>
            <ul className="list-disc list-inside text-gray-600">
              <li>Fire Safety Codes</li>
              <li>OSHA Guidelines</li>
              <li>Environmental Standards</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tools; 