import React from 'react';

const MissionVision = () => {
  return (
    <section className="bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-3xl font-bold text-gray-900">Our Mission & Vision</h2>
          <p className="mt-4 text-xl text-gray-600">
            Committed to sustainability and excellence in energy solutions
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div className="bg-blue-50 p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow">
          <h2 className="text-2xl font-bold mb-4">Our Mission</h2>
          <p className="text-gray-700">
            To provide innovative and sustainable energy solutions that empower our clients and 
            contribute to a greener future, while maintaining the highest standards of quality and service.
          </p>
        </div>
        <div className="bg-green-50 p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow">
          <h2 className="text-2xl font-bold mb-4">Our Vision</h2>
          <p className="text-gray-700">
            To be the leading provider of energy-efficient solutions in Bangladesh, recognized for 
            our commitment to sustainability and technical excellence.
          </p>
        </div>
        </div>
      </div>
    </section>
  );
};

export default MissionVision;
