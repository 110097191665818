import React from 'react';
import { useParams, Link } from 'react-router-dom';

const solutions = [
  {
    title: "Sustainable Services",
    description: "Eco-friendly solutions focused on energy efficiency, environmental impact, and renewable energy.",
    items: [
      "Energy Audit", 
      "LEED Consultancy", 
      "Environment Management Plan (EMP)", 
      "Environment Impact Assessment (EIA)", 
      "Solar Power Plant Feasibility Study"
    ],
    image: "/assets/SERVICE/Sustainable Services.jpg",
  },
  {
    title: "Safety Service",
    description: "Comprehensive safety solutions for fire protection, electrical safety, and risk mitigation.",
    items: [
      "Fire Safety Plan", 
      "Fire Detection System Design", 
      "Fire Protection System Design", 
      "Fire Separation System Design", 
      "RSC T&C Approval Service", 
      "LPS Design", 
      "Electrical SLD Design", 
      "Electrical Layout Design", 
      "Earthing Design", 
      "DEA (Structural)", 
      "Retrofitting Design", 
      "Electrical Safety Testing for Nirapon & RSC"
    ],
    image: "/assets/SERVICE/Safety Service.jpg",
  },
  {
    title: "Audit & Inspection (Service)",
    description: "Professional assessment services to ensure safety and compliance with industry standards.",
    items: [
      "Fire Safety Assessment as per NFPA, BNBC, NTPA & BFSCD Standard", 
      "Electrical Safety Assessment as per NFPA, BNBC, NTPA & BFSCD Standard", 
      "Structural Safety Assessment as per BNBC & NTPA Standard"
    ],
    image: "/assets/SERVICE/Audit & Inspection.jpg",
  },
  {
    title: "Information Technology (Service)",
    description: "Cutting-edge technology solutions including AI, IoT, and cyber security for industrial and commercial applications.",
    items: [
      "AI Based Industrial & Commercial Solution", 
      "IoT based Industrial & Commercial Solution", 
      "Cyber Security Consultancy, Assessment & Product", 
      "Industrial ERP Solution"
    ],
    image: "/assets/SERVICE/Information Technology.jpg",
  },
  {
    title: "Annual Maintenance Support",
    description: "Ongoing maintenance services to ensure continuous operation and safety of fire, electrical, and IT systems.",
    items: [
      "Fire Maintenance", 
      "Electrical Maintenance", 
      "IT Maintenance"
    ],
    image: "/assets/SERVICE/Annual Maintenance Support.jpg",
  }
];

const ServiceDetail = () => {
  const { id } = useParams();
  const service = solutions[id];

  if (!service) {
    return <div className="text-center text-xl text-red-500">Service not found!</div>;
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 mt-4 mb-8">
      <div className="text-center mb-12">
        <h1 className="text-4xl font-bold text-gray-900 mb-4">{service.title}</h1>
        {/* <img src={service.image} alt={`${service.title} Image`} className="mx-auto mb-4 max-w-full" /> */}
        <p className="text-xl text-gray-600">{service.description}</p>
      </div>
      <div className="flex space-x-8">
        <div className="w-1/2">
          <h3 className="text-2xl font-bold mb-4">Services Offered:</h3>
          <ul className="space-y-2">
            {service.items.map((item, idx) => (
              <li key={idx} className="flex items-start">
                <svg className="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                </svg>
                {item}
              </li>
            ))}
          </ul>
        </div>
        <div className="w-1/2">
          <img src={service.image} alt={`${service.title} Image`} className="mx-auto" />
        </div>
      </div>
    </div>
  );
};

export default ServiceDetail;
