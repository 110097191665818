const services = [
  {
    title: "Sustainable Services",
    description: "Eco-friendly solutions for a greener future",
    icon: "🌿"
  },
  {
    title: "Safety Service",
    description: "Expert safety solutions for a secure environment",
    icon: "🛡️"
  },
  {
    title: "Audit & Inspection",
    description: "Comprehensive audits and inspections for optimal performance",
    icon: "🔍"
  },
  {
    title: "Information Technology",
    description: "Innovative IT solutions for a connected world",
    icon: "💻"
  },
  {
    title: "Annual Maintenance",
    description: "Regular maintenance for optimal equipment performance",
    icon: "🔧"
  },
  {
    title: "Support",
    description: "Dedicated support for all your engineering needs",
    icon: "🤝"
  }
];

const Services = () => {
  return (
    <section className="py-20 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold leading-10 tracking-tight text-gray-900 sm:text-5xl lg:text-6xl xl:text-6xl">Our Services</h2>
          <p className="mt-4 text-xl text-gray-600">
            Comprehensive solutions for your engineering needs
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {services.map((service, index) => (
            <div 
              key={index}
              className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow"
            >
              <div className="text-4xl mb-4">{service.icon}</div>
              <h3 className="text-xl font-semibold mb-2">{service.title}</h3>
              <p className="text-gray-600">{service.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services; 