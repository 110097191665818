const Footer = () => {
  return (
    <footer className="bg-gray-900 text-white">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          {/* Company Info */}
          <div>
            <h3 className="text-xl font-bold mb-4">Ultra Engineering</h3>
            <p className="text-gray-400">
            Your Sustainable Solutions Partner.
            </p>
            <p className="text-gray-400 mt-2">Innovative solutions for government, industry, and commerce</p>
          </div>

          {/* Contact Info */}
          <div>
            <h3 className="text-lg font-semibold mb-4">Contact Us</h3>
            <div className="space-y-2 text-gray-400">
              {/* <p>Phone: +8801963775265</p>
              <p>Hotline: +8801674524163</p> */}
              <p className="text-gray-400">
              House No.02, Road no. 08,<br />
              Mirpur-02, Bangladesh
              </p>
              <p>Hotline: +8801963775265</p>
              <p>Email: ultraengineeringbd@gmail.com</p>
              
            </div>
          </div>

          {/* Quick Links */}
          <div>
            <h3 className="text-lg font-semibold mb-4">Quick Links</h3>
            <ul className="space-y-2">
              <li><a href="/about" className="text-gray-400 hover:text-white">About Us</a></li>
              <li><a href="/products" className="text-gray-400 hover:text-white">Products</a></li>
              <li><a href="/services" className="text-gray-400 hover:text-white">Services</a></li>
              <li><a href="/career" className="text-gray-400 hover:text-white">Career</a></li>
              <li><a href="/contact" className="text-gray-400 hover:text-white">Contact</a></li>
            </ul>
          </div>

          {/* Social Media */}
          <div>
            <h3 className="text-lg font-semibold mb-4">Follow Us</h3>
            <ul className="space-y-2">
            <li><a href="https://www.facebook.com/UEngineeringbd" className="text-gray-400 hover:text-white"><img src="/assets/fb.png" alt="Facebook" className="inline-block w-4 h-4 mr-2" />Facebook</a></li>
            <li><a href="http://wa.me/+8801963775265" className="text-gray-400 hover:text-white"><img src="/assets/wp.png" alt="WhatsApp" className="inline-block w-4 h-4 mr-2" />WhatsApp <p className="text-gray-400 mt-2">+8801963775265</p></a></li>
            </ul>
          </div>
        </div>

        <div className="mt-8 pt-8 border-t border-gray-800 text-center text-gray-400">
          <p>&copy; {new Date().getFullYear()} Ultra Engineering. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer; 