import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

const Hero = () => {
  const images = [
    '/assets/Hero/1.jpg',
    '/assets/Hero/2.jpg',
    '/assets/Hero/3.jpg',
    '/assets/Hero/4.jpg',
    '/assets/Hero/5.jpg',
    '/assets/Hero/6.jpg',
    '/assets/Hero/7.jpg',
  ];

  const [currentImage, setCurrentImage] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [images]);

  return (
    <div className="relative bg-black text-white h-screen w-full overflow-hidden">
      <TransitionGroup>
        <CSSTransition key={currentImage} timeout={1000} classNames="slide">
          <div className="absolute inset-0 z-10">
            <img
              src={images[currentImage]}
              alt="Hero"
              className="object-cover w-full h-full brightness-100"
            />
          </div>
        </CSSTransition>
      </TransitionGroup>
      <div className="absolute inset-0 flex items-center justify-center z-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24 mt-30">
          <div className="text-center">
            <h1 className="text-4xl md:text-6xl font-bold mb-6 inline-block px-4">
              Your Sustainable Solutions Partner
            </h1>
            <p className="text-xl md:text-2xl mb-8 inline-block px-4">
              Innovative solutions for government, industry, and commerce
            </p>
            <div className="flex justify-center gap-4">
              <Link
                to="/services"
                className="bg-white text-blue-900 px-8 py-3 rounded-lg font-semibold hover:bg-blue-100 transition-colors"
              >
                Our Services
              </Link>
              <Link
                to="/contact"
                className="border-2 border-white px-8 py-3 rounded-lg font-semibold hover:bg-white hover:text-blue-900 transition-colors"
              >
                Get quote
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;