import React from 'react';

const About = () => {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <div className="text-center mb-12 relative">
        <img 
          src="/assets/aboutUS.jpg" 
          alt="About Us Image"
          className="mx-auto object-cover w-full h-96 md:h-128 opasity-10 brightness-75"
        />
        <h1 className="text-5xl font-bold text-white absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 z-10 md:text-4xl sm:text-3xl xs:text-2xl">
          About Ultra Engineering
          <p className="text-xl font-semibold text-white mt-4">Leading the way in energy-saving technology since 2017</p>
        </h1>

      </div>

      {/* Company Overview */}
      <div className="mb-16">
        <h2 className="text-2xl font-bold mb-4">Company Overview</h2>
        <p className="text-gray-700 mb-4">
        Founded in 2017, Ultra Engineering is a leader in Sustainable technology and electromechanical solutions. We specialize in providing world-class services and innovative products to government, industry, and commerce clients.
        </p>

        <p className="text-gray-700">
        Our expertise spans various services, including sustainable power management like solar power, windmills, Fire safety solutions, Artificial intelligence, IOT-based solutions prefabricated steel structures, retrofitting solutions, and complete energy management systems. From design and precision production to safe delivery and installation, we ensure excellence at every project stage. We are the only Bangladeshi company that provides all engineering services and solutions under one umbrella.
        </p>

        <p className="text-gray-700 mt-4">
        Over the past decade, we’ve successfully executed numerous prestigious projects in power generation, distribution systems, energy saving, and lighting automation. Our focus on quality, timely delivery, and exceptional after-sales service has made us a trusted name in the power sector.
        </p>

        <p className="text-gray-700 mt-4">
        Committed to sustainability, we are advancing green and clean energy initiatives aligned with SDG-2030. We have a multi-skilled technical team consists Lean Six Sigma Black Belt, PMP, MBA, NFPA Certified, SREDA Certified, and other skilled human resources with vast experience.
        </p>

        <p className="text-gray-700 mt-4">
        At Ultra Engineering, innovation drives us. With a skilled technical team, we continuously develop cutting-edge technologies to meet and exceed our clients' needs. Together, we’re building a brighter, sustainable future.
        </p>
      </div>

      {/* Mission & Vision */}
      <div className="grid md:grid-cols-2 gap-8 mb-16">
        <div className="bg-blue-50 p-6 rounded-lg">
          <h2 className="text-2xl font-bold mb-4">Our Mission</h2>
          <p className="text-gray-700">
            To provide innovative and sustainable energy solutions that empower our clients and 
            contribute to a greener future, while maintaining the highest standards of quality and service.
          </p>
        </div>
        <div className="bg-green-50 p-6 rounded-lg">
          <h2 className="text-2xl font-bold mb-4">Our Vision</h2>
          <p className="text-gray-700">
            To be the leading provider of energy-efficient solutions in Bangladesh, recognized for 
            our commitment to sustainability and technical excellence.
          </p>
        </div>
      </div>

      {/* Core Values */}
      {/* <div className="mb-16">
        <h2 className="text-2xl font-bold mb-6">Our Core Values</h2>
        <div className="grid md:grid-cols-4 gap-4">
          {['Innovation', 'Quality', 'Safety', 'Customer Satisfaction'].map((value, index) => (
            <div key={index} className="text-center p-4 bg-gray-50 rounded-lg">
              <h3 className="font-bold text-lg mb-2">{value}</h3>
            </div>
          ))}
        </div>
      </div> */}
    </div>
  );
};

export default About;
