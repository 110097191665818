import Hero from '../components/home/Hero';
import Services from '../components/home/Services';
import Testimonials from '../components/home/Testimonials';
import FeaturedProjects from '../components/home/FeaturedProjects';
import MissionVision from '../components/home/MissionVision';
import CEOsMessage from '../components/home/CEOsMessage';
import AboutUs from '../components/home/AboutUs';

function Home() {
    return (
        <div>
            <Hero />
            <AboutUs />
            <MissionVision />
            <CEOsMessage />
            <Services />
            {/* <FeaturedProjects /> */}
            <Testimonials />
        </div>
    );
}

export default Home;