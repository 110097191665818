const newsItems = [
  {
    title: "Ultra Engineering Completes Major Solar Installation",
    date: "March 15, 2024",
    category: "Projects",
    summary: "Successfully completed a 500kW solar installation for a major industrial client.",
    image: "https://via.placeholder.com/400x300"
  },
  {
    title: "New Fire Safety Solutions Launch",
    date: "March 1, 2024",
    category: "Products",
    summary: "Introducing our latest range of advanced fire safety systems with smart detection.",
    image: "https://via.placeholder.com/400x300"
  },
  {
    title: "Sustainability Achievement Award",
    date: "February 20, 2024",
    category: "Awards",
    summary: "Ultra Engineering recognized for contributions to sustainable energy solutions.",
    image: "https://via.placeholder.com/400x300"
  }
];

const News = () => {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <div className="text-center mb-12">
        <h1 className="text-4xl font-bold text-gray-900 mb-4">Latest News</h1>
        <p className="text-xl text-gray-600">
          Stay updated with our latest projects, products, and achievements
        </p>
      </div>

      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
        {newsItems.map((news, index) => (
          <div key={index} className="bg-white rounded-lg shadow-lg overflow-hidden">
            <img
              src={news.image}
              alt={news.title}
              className="w-full h-48 object-cover"
            />
            <div className="p-6">
              <div className="flex justify-between items-center mb-2">
                <span className="text-blue-600 text-sm font-semibold">
                  {news.category}
                </span>
                <span className="text-gray-500 text-sm">{news.date}</span>
              </div>
              <h3 className="text-xl font-bold mb-2">{news.title}</h3>
              <p className="text-gray-600 mb-4">{news.summary}</p>
              <button className="text-blue-600 font-semibold hover:text-blue-800">
                Read More →
              </button>
            </div>
          </div>
        ))}
      </div>

      {/* Newsletter Subscription */}
      <div className="mt-16 bg-blue-50 p-8 rounded-lg">
        <div className="text-center">
          <h2 className="text-2xl font-bold mb-4">Subscribe to Our Newsletter</h2>
          <p className="text-gray-600 mb-6">
            Stay informed about our latest news and updates
          </p>
          <form className="max-w-md mx-auto">
            <div className="flex gap-4">
              <input
                type="email"
                placeholder="Enter your email"
                className="flex-1 px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:border-blue-500"
              />
              <button
                type="submit"
                className="bg-blue-600 text-white px-6 py-2 rounded-lg hover:bg-blue-700 transition-colors"
              >
                Subscribe
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default News; 