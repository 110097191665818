import React from 'react';
import { useParams, Link } from 'react-router-dom';

const solutions = [
  {
    title: "Sustainable Services",
    description: "Eco-friendly solutions focused on energy efficiency, environmental impact, and renewable energy.",
    items: [
      "Energy Audit", 
      "LEED Consultancy", 
      "Environment Management Plan (EMP)", 
      "Environment Impact Assessment (EIA)", 
      "Solar Power Plant Feasibility Study"
    ],
    image: "/assets/SERVICE/Sustainable Services.jpg",
  },
  {
    title: "Safety Services",
    description: "Comprehensive safety solutions for fire protection, electrical safety, and risk mitigation.",
    items: [
      "Fire Safety Plan", 
      "Fire Detection System Design", 
      "Fire Protection System Design", 
      "Fire Separation System Design", 
      "RSC T&C Approval Service", 
      "LPS Design", 
      "Electrical SLD Design", 
      "Electrical Layout Design", 
      "Earthing Design", 
      "DEA (Structural)", 
      "Retrofitting Design", 
      "Electrical Safety Testing for Nirapon & RSC"
    ],
    image: "/assets/SERVICE/Safety Service.jpg",
  },
  {
    title: "Audit & Inspection Services",
    description: "Professional assessment services to ensure safety and compliance with industry standards.",
    items: [
      "Fire Safety Assessment as per NFPA, BNBC, NTPA & BFSCD Standard", 
      "Electrical Safety Assessment as per NFPA, BNBC, NTPA & BFSCD Standard", 
      "Structural Safety Assessment as per BNBC & NTPA Standard"
    ],
    image: "/assets/SERVICE/Audit & Inspection.jpg",
  },
  {
    title: "Information Technology Services",
    description: "Cutting-edge technology solutions including AI, IoT, and cyber security for industrial and commercial applications.",
    items: [
      "AI Based Industrial & Commercial Solution", 
      "IoT based Industrial & Commercial Solution", 
      "Cyber Security Consultancy, Assessment & Product", 
      "Industrial ERP Solution"
    ],
    image: "/assets/SERVICE/Information Technology.jpg",
  },
  {
    title: "Annual Maintenance Support",
    description: "Ongoing maintenance services to ensure continuous operation and safety of fire, electrical, and IT systems.",
    items: [
      "Fire Maintenance", 
      "Electrical Maintenance", 
      "IT Maintenance"
    ],
    image: "/assets/SERVICE/Annual Maintenance Support.jpg",
  }
];

const Services = () => {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <div className="text-center mb-12">
        <h1 className="text-4xl font-bold text-gray-900 mb-4">Our Services</h1>
        <p className="text-xl text-gray-600">
          Comprehensive engineering solutions for all your needs
        </p>
      </div>

      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
        {solutions.map((solution, index) => (
          <div key={index} className="bg-white rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300 overflow-hidden">
            <Link to={`/service/${index}`}>
              <div className="overflow-hidden">
                <img src={solution.image} alt={solution.title} className="w-full h-48 object-cover" />
                <h2 className="text-2xl font-bold text-black px-6 mt-6">{solution.title}</h2>
                <p className="text-gray-600 px-6 mt-4 mb-6">{solution.description}</p>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Services;
