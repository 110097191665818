import React from 'react';

const AboutUs = () => {
	return (
		<section className="py-20 bg-gray-50 flex items-center justify-center">
			<div className="container md:p-6 lg:p-8">
				<div className="text-center">
				<h2 className="text-4xl font-bold leading-10 tracking-tight text-gray-900 sm:text-5xl lg:text-6xl xl:text-6xl">About Us</h2>
					{/* <div className="bg-white p-6 rounded-lg shadow-lg"></div> */}
					<p className="bg-white p-6 rounded-lg shadow-lg mt-4 text-lg text-gray-600 mx-16">
						Founded in 2017, Ultra Engineering is a Bangladeshi leader in sustainable technology, offering solar power, windmills, fire safety, AI, IoT solutions, and energy management under one roof. With a skilled, certified team, we deliver excellence from design to installation, completing major projects in power, energy savings, and automation. Committed to SDG-2030, we drive innovation and sustainability for a brighter future.
					</p>
				</div>
			</div>
		</section>
	);
};

export default AboutUs;
