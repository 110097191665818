const projects = [
  {
    title: "Industrial Power Plant",
    category: "Power Generation",
    description: "Installation of 5MW power plant for manufacturing facility",
    image: "https://martech.com.vn/pictures/catalog/nhiet-dien/nhietdien-1696406915685286066212.jpg",
    completion: "2023"
  },
  {
    title: "Solar Installation",
    category: "Renewable Energy",
    description: "Large-scale solar installation for commercial complex",
    image: "https://coldwellsolar.com/wp-content/uploads/2021/10/designing-commercial-solar-installation.jpg",
    completion: "2023"
  },
  {
    title: "Fire Safety System",
    category: "Safety Solutions",
    description: "Comprehensive fire protection system for hotel",
    image: "https://media.licdn.com/dms/image/v2/D5612AQFPwPmaJV30Lg/article-cover_image-shrink_720_1280/article-cover_image-shrink_720_1280/0/1693193163741?e=2147483647&v=beta&t=mhuoybwz5MO9MnadDLGA3apX51rysJ1tCDj9oJ1EJlU",
    completion: "2022"
  }
];

const Projects = () => {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <div className="text-center mb-12">
        <h1 className="text-4xl font-bold text-gray-900 mb-4">Our Projects</h1>
        <p className="text-xl text-gray-600">
          Showcasing our successful implementations across various sectors
        </p>
      </div>

      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
        {projects.map((project, index) => (
          <div key={index} className="bg-white rounded-lg overflow-hidden shadow-lg hover:shadow-xl transition-shadow">
            <img
              src={project.image}
              alt={project.title}
              className="w-full h-48 object-cover"
            />
            <div className="p-6">
              <div className="flex justify-between items-center mb-2">
                <span className="text-blue-600 text-sm font-semibold">
                  {project.category}
                </span>
                <span className="text-gray-500 text-sm">
                  Completed: {project.completion}
                </span>
              </div>
              <h3 className="text-xl font-bold mb-2">{project.title}</h3>
              <p className="text-gray-600">{project.description}</p>
              {/* <button className="mt-4 text-blue-600 font-semibold hover:text-blue-800">
                View Details →
              </button> */}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Projects; 