import React, { useState } from 'react';
import emailjs from 'emailjs-com';

const Career = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    cvLink: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    console.log('Form data to be sent:', formData);
    emailjs.send('service_ov87f4r', 'template_b2vznd2', formData, 'U-inWMCG1IeypPVu6')
      .then((result) => {
        console.log('Email sent successfully', result.text);
        alert('Email sent successfully!');
        setFormData({
          name: '',
          email: '',
          cvLink: '',
          message: '',
        });
      })
      .catch((error) => {
        console.error('Email sending failed', error.text);
        alert('Email sending failed. Please try again.');
      });
  };

  return (
    <div>
      <div className="max-w-md mx-auto bg-white shadow-lg rounded-lg p-6 mt-20 mb-20">
        <h1 className="text-2xl font-bold text-center mb-4">Join Our Team</h1>
        <p className="text-center mb-6">We'd love to hear from you! Please fill out the form below.</p>
        <form onSubmit={handleSubmit} className="mt-6">
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
              Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Your Name"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Your Email"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="cvLink">
              CV Link
            </label>
            <input
              type="url"
              id="cvLink"
              name="cvLink"
              value={formData.cvLink}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Your CV Link"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="message">
              Message
            </label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-32"
              placeholder="Your message..."
              required
            ></textarea>
          </div>
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default Career;
