import { useState, useEffect } from 'react';

const testimonials = [
  { name: "Client 1", role: "Role 1", image: "/assets/Client Logos/1.png" },
  { name: "Client 2", role: "Role 2", image: "/assets/Client Logos/2.png" },
  { name: "Client 3", role: "Role 3", image: "/assets/Client Logos/3.png" },
  { name: "Client 4", role: "Role 4", image: "/assets/Client Logos/4.png" },
  { name: "Client 5", role: "Role 5", image: "/assets/Client Logos/5.png" },
  { name: "Client 6", role: "Role 6", image: "/assets/Client Logos/6.png" },
  { name: "Client 7", role: "Role 7", image: "/assets/Client Logos/7.png" },
  { name: "Client 8", role: "Role 8", image: "/assets/Client Logos/8.png" },
  { name: "Client 9", role: "Role 9", image: "/assets/Client Logos/9.png" }
];

const Testimonials = () => {
  return (
    <section className="py-20 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-4xl font-bold text-center mb-12">Industries We Work With</h2>
        <div className="overflow-hidden relative">
          <div 
            className="flex whitespace-nowrap animate-scroll"
            style={{
              animation: 'scroll 20s linear infinite',
              display: 'flex',
              gap: '1rem',
            }}
          >
            {[...testimonials, ...testimonials].map((testimonial, index) => (
              <div
                key={`${testimonial.name}-${index}`}
                className="inline-flex justify-center items-center min-w-[16.666%] transform scale-90 hover:scale-95 transition-transform"
              >
                <img
                  src={testimonial.image}
                  alt={`${testimonial.name} logo`}
                  className="max-w-full object-contain h-24 w-auto"
                />
              </div>
            ))}
          </div>
          <style jsx>{`
            @keyframes scroll {
              0% {
                transform: translateX(0);
              }
              100% {
                transform: translateX(-50%);
              }
            }
            .animate-scroll {
              animation: scroll 20s linear infinite;
            }
          `}</style>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
