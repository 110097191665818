import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './components/layout/Layout';
import ScrollToTop from './components/layout/ScrollToTop';
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import Projects from './pages/Projects';
import Products from './pages/Products';
import Tools from './pages/Tools';
import Gallery from './pages/Gallery';
import News from './pages/News';
import Contact from './pages/Contact';
import Career from './pages/Career';
import ServiceDetail from './pages/services/ServiceDetail';
import ProductDetail from './pages/products/ProductDetail';

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/products" element={<Products />} />
          <Route path="/tools" element={<Tools />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/news" element={<News />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/career" element={<Career />} />
          <Route path="/service/:id" element={<ServiceDetail />} />
          <Route path="/product/:productId" element={<ProductDetail />} />
        </Routes>
      </Layout>
    </Router>
  );
};

export default App;
