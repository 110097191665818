import React from 'react';

const CEOsMessage = () => {
    return (
        <section className="py-5 bg-gray-50">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="text-center mb-16">
                    {/* <h2 className="text-3xl font-bold text-gray-900">Engineer Hasan Hafizul Islam</h2>
                    <p className="mt-4 text-xl text-gray-600">
                    We welcome you to Ultra Engineering. We have built a reputation for quality over the years by providing innovative energy-saving technology and IT solutions. Our experience in energy, safety, and information technology enables us to serve the government, industrial, and commercial sectors with honesty and precision.

                    Ultra Engineering is dedicated to accelerating development, enabling industries, and developing long-term solutions for a better future. Thank you for entrusting us to help you succeed.
                    </p> */}
                </div>
                <div className="bg-white p-4 rounded-lg shadow-md md:p-6 md:rounded-lg md:shadow-lg">
                  <div className="flex flex-col items-center md:flex-row md:items-start">
                    <img 
                      className="w-full h-auto max-w-[200px] md:w-[200px] md:h-[200px]" 
                      src={`${process.env.PUBLIC_URL}/assets/CEO.jpg`} 
                      alt="Engineer Hasan Hafizul Islam"
                    />
                    <div className="md:ml-6 mt-4 md:mt-0">
                      <h2 className="text-2xl font-semibold md:text-4xl">Message from CEO</h2>
                      <p className="mt-2 text-sm text-gray-700 md:text-base text-justify">
                        We welcome you to Ultra Engineering. We have built a reputation for quality over the years by providing innovative energy-saving technology and IT solutions. Our experience in energy, safety, and information technology enables us to serve the government, industrial, and commercial sectors with honesty and precision.
                      </p>
                      <p className="mt-2 text-sm text-gray-700 md:text-base text-justify">
                        Ultra Engineering is dedicated to accelerating development, enabling industries, and developing long-term solutions for a better future. Thank you for entrusting us to help you succeed.
                      </p>
                      <h2 className="text-sm font-semibold mt-2 md:text-base">- Engr. Hasan Hafizul Islam</h2>
                    </div>
                  </div>
                </div>
            </div>
        </section>
    );
};

export default CEOsMessage;
