const images = [
  { url: "/assets/Gallery/image (1).jpg" },
  { url: "/assets/Gallery/image (2).jpg" },
  { url: "/assets/Gallery/image (3).jpg" },
  { url: "/assets/Gallery/image (4).jpg" },
  { url: "/assets/Gallery/image (5).jpg" },
  { url: "/assets/Gallery/image (6).jpg" },
  { url: "/assets/Gallery/image (7).jpg" },
  { url: "/assets/Gallery/image (8).jpg" },
  { url: "/assets/Gallery/image (9).jpg" },
  { url: "/assets/Gallery/image (10).jpg" },
  { url: "/assets/Gallery/image (11).jpg" },
  { url: "/assets/Gallery/image (12).jpg" },
  { url: "/assets/Gallery/image (13).jpg" },
  { url: "/assets/Gallery/image (14).jpg" },
  { url: "/assets/Gallery/image (15).jpg" },
  { url: "/assets/Gallery/image (16).jpg" },
  { url: "/assets/Gallery/image (17).jpg" },
  { url: "/assets/Gallery/image (18).jpg" },
  { url: "/assets/Gallery/image (19).jpg" },
  { url: "/assets/Gallery/image (20).jpg" },
  { url: "/assets/Gallery/image (21).jpg" },
  { url: "/assets/Gallery/image (22).jpg" },
  { url: "/assets/Gallery/image (23).jpg" },
  { url: "/assets/Gallery/image (24).jpg" },
  { url: "/assets/Gallery/image (25).jpg" },
  { url: "/assets/Gallery/image (26).jpg" },
  { url: "/assets/Gallery/image (27).jpg" },
  { url: "/assets/Gallery/image (28).jpg" },
  { url: "/assets/Gallery/image (29).jpg" },
  { url: "/assets/Gallery/image (30).jpg" },
  { url: "/assets/Gallery/image (31).jpg" },
  { url: "/assets/Gallery/image (32).jpg" },
  { url: "/assets/Gallery/image (33).jpg" },
  { url: "/assets/Gallery/image (34).jpg" },
  { url: "/assets/Gallery/image (35).jpg" },
  { url: "/assets/Gallery/image (36).jpg" },
  { url: "/assets/Gallery/image (37).jpg" }
];

const Gallery = () => {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <div className="text-center mb-12">
        <h1 className="text-4xl font-bold text-gray-900 mb-4">Gallery</h1>
        <p className="text-xl text-gray-600">
          Visual showcase of our projects, products, and team
        </p>
      </div>

      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
        {images.map((image, index) => (
          <div key={index} className="relative">
            <img
              src={image.url}
              alt={`Gallery image ${index + 1}`}
              className="w-full h-64 object-cover rounded-lg shadow-lg"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Gallery; 
